import * as React from "react";
import * as styles from "./Info.module.css";

import TitleUnderline from "../title_underline/TitleUnderline";

const Info = () => {
  return (
    <div className={styles.info}>
      <TitleUnderline
        title="営業時間・アクセス"
        color="#fff"
        underlineColor="#fff"
      />

      <div className={styles.content}>
        <div className={styles.textWrapper}>
          <h2 className={styles.title}>営業時間</h2>
          <div className={styles.description}>17:00～23:00(L.O.22:00)</div>
          <h2 className={styles.title}>電話番号</h2>
          <div className={styles.description}>TEL：093-953-8252</div>
        </div>
        <div className={styles.mapWrapper}>
          <h2 className={styles.title}>アクセス</h2>
          <div className={styles.accessdescription}>
            住所：小倉北区片野1丁目1-3
            <br />
            駐車場：近隣にコインパーキングあり
          </div>

          <div className={styles.map}>
            <iframe
              title="Google Maps"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3312.713566026281!2d130.87845827545564!3d33.871272027265036!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3543c0aa4d8c3daf%3A0xe0b12ce44fc476f!2z44CSODAyLTAwNjQg56aP5bKh55yM5YyX5Lmd5bee5biC5bCP5YCJ5YyX5Yy654mH6YeO77yR5LiB55uu77yR4oiS77yTIO-8q--8qeODk-ODq-S4ieiQqemHjg!5e0!3m2!1sja!2sjp!4v1717834254948!5m2!1sja!2sjp"
              allowFullScreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Info;
