import * as React from "react";
import Info from "../components/Info/Info";
import Seo from "../components/seo";
import Layout from "../layout";

const InfoPage = () => {
  return (
    <Layout>
      <Info />
    </Layout>
  );
};

export default InfoPage;

export const Head = () => (
  <Seo
    description="営業時間: 17:00～23:00(L.O.22:00) アクセス
住所：小倉北区片野1丁目1-3
駐車場：近隣にコインパーキングあり"
    title="営業時間・アクセス"
  />
);
